<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submitForm)">
      <validation-provider v-slot="{ errors }" name="username" rules="required">
        <v-text-field
          v-model="form.username"
          :label="$t('fields.username')"
          :prepend-inner-icon="mdiAccount"
          :error-messages="errors"
          counter="20"
          maxlength="20"
          outlined
          autocomplete="username"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="password" rules="required">
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          v-model="form.password"
          :label="$t('fields.password')"
          :prepend-inner-icon="mdiLock"
          :append-icon="showPassword ? mdiEye : mdiEyeOff"
          :error-messages="errors"
          maxlength="128"
          outlined
          autocomplete="current-password"
        />
      </validation-provider>
      <v-checkbox
        color="secondary"
        v-model="form.remember"
        :label="$t('wot.rememberMe')"
      />
      <v-btn color="primary" type="submit" >{{ $t('signIn') }}</v-btn>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  mdiAccount, mdiLock, mdiEye, mdiEyeOff,
} from '@mdi/js';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      username: '',
      password: '',
      remember: false,
    },
    showPassword: false,
    mdiAccount,
    mdiLock,
    mdiEye,
    mdiEyeOff,
  }),
  computed: {
    ...mapFields({ authErrors: 'errors.auth', isLoading: 'isLoading', alertType: 'alertType' }),
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    async submitForm() {
      this.isLoading = true;

      await this.signIn(this.form);

      if (this.alertType === 'success') {
        this.$router.push({ name: 'home' });
      }

      this.isLoading = false;
    },
  },
  watch: {
    authErrors: {
      deep: true,
      handler(value) {
        this.$refs.observer.setErrors(value);
      },
    },
  },
};
</script>
